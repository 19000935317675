import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_5/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    data1: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "EN" }
          type: { eq: "slide-map-1" }
        }
      }
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    data2: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "EN" }
          type: { eq: "slide-map-2" }
        }
      }
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    timelineTitels: mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "timelines"}}) {
      exports {
        titleArray {
          index
          position
          text
        }
      }
      frontmatter {
        toolTip
      }
    }
    initialSlideLeft: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "EN" }
          title: { eq: "slide-4-5-0" }
        }
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    initialSlideCenter: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "EN" }
          title: { eq: "slide-4-5-0-1" }
        }
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    initialSlideRight: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "EN" }
          title: { eq: "slide-4-5-0-2" }
        }
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    descriptionsElements: allMdx(filter: {frontmatter: {language: {eq: "EN"}, title: {eq: "slide-4-5-0-desc"}}}) {
      nodes {
        frontmatter {
          text1
          text2
        }
      }
    }
  }
  `);

  return (
    <Template
      titles={query.timelineTitels}
      query={{ 1: query.data1.edges, 2: query.data2.edges }}
      timelineTexts={query.descriptionsElements.nodes[0].frontmatter}
      initialScreen={{
        left: query.initialSlideLeft.edges[0].node.body,
        center: query.initialSlideCenter.edges[0].node.body,
        right: query.initialSlideRight.edges[0].node.body,
      }}
    />
  );
};


export default Slide;
