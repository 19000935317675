import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/en/chapter_4/sub_5/slide1';

import WrapperMobile from 'src/slides/mobile/en/chapter_4/sub_5/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Plan and course | The Battle of Warsaw" lang="en" description="Animated history of the Battle of Warsaw." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Plan and course | The Battle of Warsaw" lang="en" description="Animated history of the Battle of Warsaw." />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
